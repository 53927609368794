<template>
    <div>
        <div class="search-bar-wrapper">
            <div class="relative search-bar-container">
                <input type="text"
                       class="partner-search"
                       placeholder="Search for partners"
                       v-model="search">
                <div class="reset-text-button"
                     @click="ClearSearchBar">
                    <i class="bi bi-x-circle-fill"></i>
                    <span id="tooltip">Clear Search</span>
                </div>
            </div>
        </div>
        <!-- Show all of the partners when the search bar is empty -->
        <div class="partner-grid-container"
             v-if="search == ''">
            <div class="partner-wrapper"
                 v-for="partner of partners"
                 :key="partner">
                <div class="partner"
                     :class="{ 'grid': layout === 'grid', 'list': layout === 'list' }">
                    <div class="partner-logo">
                        <!-- <img v-if="!partner.image" src="https://picsum.photos/150/80"> -->
                        <img v-if="partner.image"
                             :src="'data:image/jpeg;base64,' + partner.image"
                             width="150"
                             height="80"
                             :alt="partner.name" />
                    </div>
                    <div class="partner-label-country flex align-center margin-bottom-regular">
                        <div class="partner-label">{{ partner.partnerType }}</div>
                        <div class="partner-country">
                            <i class="bi bi-geo-alt-fill"></i>
                            <div class="country">{{ partner.country }}</div>
                        </div>
                    </div>
                    <div class="partner-name-wrapper">
                        <h4 class="partner-name">{{ partner.name }}</h4>
                    </div>
                    <div class="partner-description-wrapper">
                        <div class="partner-description">{{ partner.description }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Show the resulting partners when there is a search term -->
        <div class="partner-grid-container"
             v-if="search != ''">
            <div class="partner-wrapper"
                 v-for="partner of SearchPartners"
                 :key="partner">
                <div class="partner"
                     :class="{ 'grid': layout === 'grid', 'list': layout === 'list' }">
                    <div class="partner-logo">
                        <!-- <img v-if="!partner.image" src="https://picsum.photos/150/80"> -->
                        <img v-if="partner.image"
                             :src="'data:image/jpeg;base64,' + partner.image"
                             width="150"
                             height="80"
                             :alt="partner.name" />
                    </div>
                    <div class="flex align-center margin-bottom-regular">
                        <div class="partner-label">{{ partner.partnerType }}</div>
                        <div class="partner-country">
                            <i class="bi bi-geo-alt-fill"></i>
                            <div class="country">{{ partner.country }}</div>
                        </div>
                    </div>
                    <div class="partner-name-wrapper">
                        <h4 class="partner-name">{{ partner.name }}</h4>
                    </div>
                    <div class="partner-description-wrapper">
                        <div class="partner-description">{{ partner.description }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- No Results Found -->
        <div class="no-results-wrapper"
             v-show="noResults">
            <div class="no-results-icon">
                <i class="bi bi-search"></i>
            </div>
            <div class="no-results-heading">
                No results found.
            </div>
            <div class="no-results-text">
                We can't find any item matching your search.
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data: () => ({
        partnerData: [],
        partners: [],
        search: "",
        filterPartnerType: "", filterPartnerCountry: "",
        noResults: false,
        layout: "grid",
        windowWidth: window.innerWidth, windowHeight: window.innerHeight
    }),
    created() {
        this.partners = this.partnerData;
        window.addEventListener("resize", this.onResize);
        if (this.windowWidth < 600 || this.windowWidth >= 900) {
            this.layout = "grid";
        }
        if (this.windowWidth >= 600 && this.windowWidth < 900) {
            this.layout = "list";
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    watch: {
        partners() {
            this.CheckForFilteredPartnerResults();
        },
        SearchPartners() {
            this.CheckForFilteredPartnerResults();
        },
        layout: function () {
            if (this.layout == 'grid') {
                document.querySelector('.partner-grid-container').style.gridTemplateColumns = "repeat(auto-fill, 400px)";
            }
            if (this.layout == 'list') {
                document.querySelector('.partner-grid-container').style.gridTemplateColumns = "100%";
            }
        },
    },
    computed: {
        SearchPartners: function () {
            if (this.search != "") {
                return this.partnerData.filter((partner) => {
                    return partner.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            } else {
                return this.partnerData;
            }
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        Compare(a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        },
        PartnerFilter() {
            let tempData = JSON.parse(JSON.stringify(this.partnerData));

            if (this.filterPartnerType != "") {
                tempData = tempData.filter((partner) => partner.partnerType.toLowerCase() == this.filterPartnerType);
            }
            if (this.filterPartnerCountry != "") {
                tempData = tempData.filter((partner) => partner.geoCode == this.filterPartnerCountry);
            }

            this.partners = tempData;
        },
        CheckForFilteredPartnerResults() {
            if (this.SearchPartners.length === 0 && this.partners.length === 0) {
                this.noResults = true;
            }
            if (this.partners.length > 0 && this.SearchPartners.length === 0) {
                this.noResults = true;
            }
            if (this.SearchPartners.length > 0 && this.partners.length === 0) {
                this.noResults = false;
                if (this.search === "") {
                    this.noResults = true;
                }
            }
            if (this.SearchPartners.length > 0 && this.partners.length > 0) {
                this.noResults = false;
            }
        },
        async GetPartnerData() {
            try {
                const getPartners = await axios.get(`https://${location.host}/api/partners`);
                // console.log(getPartners);

                if (getPartners.status === 200) {
                    // Iterate over getPartners.data (which is an array of objects)
                    for (let partner of getPartners.data) {
                        this.partnerData.push(
                            {
                                name: partner.name,
                                description: partner.description,
                                country: partner.country,
                                geoCode: partner.geoCode,
                                partnerType: partner.partnerType,
                                image: partner.image
                            }
                        );
                    }
                }

            } catch (error) {
                console.log(new Error(error));
            }
        },
        ClearSearchBar() {
            this.search = "";
        },
    },
    mounted: function () {
        this.$root.$on('change-layout', (layout) => {
            if (layout == 'grid') {
                this.layout = "grid";
                document.querySelector('.partner-grid-container').style.gridTemplateColumns = "repeat(auto-fill, 400px)";
            } else if (layout == "list") {
                this.layout = "list";
                document.querySelector('.partner-grid-container').style.gridTemplateColumns = "100%";
            }
        })
        this.$root.$on('filter-partner', (type) => {
            this.filterPartnerType = type;

            if (type == "clear-all") {
                this.filterPartnerType = "";
                this.filterPartnerCountry = "";
                this.partners = this.partnerData;
            } else {
                this.PartnerFilter();
            }
        })
        this.$root.$on('filter-partner-country', (country) => {
            this.filterPartnerCountry = country;
            this.PartnerFilter();
        })
        this.GetPartnerData();
    },
}
</script>

<style scoped>
.search-bar-wrapper {
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.search-bar-wrapper .search-bar-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}

.search-bar-wrapper .search-bar-container .partner-search {
    position: relative;
    border: none;
    border-radius: 6px;
    padding: 25px 70px 25px 25px;
    width: 100%;
    max-width: 800px;
    z-index: 2;
    box-shadow: 0 3px 52px 0 rgba(0, 0, 0, 0.16);
    font-size: 18px;
    color: var(--text-dark-terniary);
    font-weight: 300;
    font-family: inherit;
    transition: box-shadow 0.4s ease;
}

.search-bar-wrapper .search-bar-container .partner-search:focus {
    outline: none !important;
    box-shadow: 0 3px 52px 0 #00bcc536 !important;
}

.search-bar-wrapper .search-bar-container .reset-text-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 25px;
    right: 14px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    color: var(--text-dark-secondary);
    font-size: 20px;
    z-index: 2;
}

.search-bar-wrapper .search-bar-container #tooltip {
    position: absolute;
    top: -20px;
    width: 100px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: opacity 0.2s ease,
        transform 0.2s ease;
}

.search-bar-wrapper .search-bar-container .reset-text-button:hover #tooltip {
    opacity: 1;
    transform: translateY(0px);
}

.search-bar-wrapper .search-bar-container .partner-search.icon {
    background-image: url("../../assets/search-icon.svg");
    background-size: 25px;
    background-repeat: no-repeat;
}

.partner-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-gap: 20px;
    justify-content: center;
    max-width: 1240px;
    margin: 0 auto;
}

.partner-grid-container a {
    text-decoration: none;
}

.partner-grid-container .partner {
    display: grid;
    justify-content: stretch;
    padding: 30px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    max-height: 433px;
    border-radius: 6px;
    overflow: hidden;
    transition: box-shadow 0.3s ease,
        transform 0.3s ease;
}

.partner-grid-container .partner.list {
    grid-template-columns: 150px auto;
    grid-template-rows: 30px 40px auto;
    grid-column-gap: 30px;
}

.partner-grid-container .partner.grid {
    grid-template-columns: 1fr;
}

.partner-grid-container .partner.list .partner-logo {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 4;
}

.partner-grid-container .partner.list .partner-label-country {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-end: 3;
    margin-bottom: 0;
}

.partner-grid-container .partner.grid .partner-label-country {
    grid-row-start: 3;
    grid-row-end: 4;
}

.partner-grid-container .partner.list .partner-name-wrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.partner-grid-container .partner.grid .partner-name-wrapper {
    grid-row-start: 2;
    grid-row-end: 3;
}

.partner-grid-container .partner.list .partner-description-wrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}

.partner-grid-container .partner .partner-logo {
    margin-bottom: 1rem;
}

.partner-grid-container .partner .partner-logo img {
    max-width: 150px;
    height: 80px;
    object-fit: contain;
}

.partner-grid-container .partner .partner-label {
    margin-right: 1rem;
    text-transform: capitalize;
}

.partner-grid-container .partner .partner-country {
    display: flex;
    align-items: center;
}

.partner-grid-container .partner .partner-country i {
    color: var(--brand-primary-blue);
    font-size: 18px;
    margin-right: 5px;
}

.partner-grid-container .partner .partner-label,
.partner-grid-container .partner .partner-country .country {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-dark-secondary);
}

.partner-grid-container .partner .partner-name-wrapper {
    position: relative;
}

.partner-grid-container .partner .partner-name-wrapper .partner-name {
    font-weight: 500;
    color: var(--dark-default);
    margin-bottom: 1rem;
    white-space: nowrap;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.partner-grid-container .partner .partner-name-wrapper .partner-name::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    background-color: var(--dark-default);
    left: 0;
    bottom: -5px;
    transition: width 0.15s ease;
}

.partner-grid-container .partner .partner-description-wrapper .partner-description {
    font-weight: 300;
    line-height: 1.5rem;
    color: var(--text-dark-secondary);
    overflow-y: auto;
    max-height: 195px;
    scrollbar-width: thin;
    padding-right: 10px;
}

.no-results-wrapper {
    max-width: var(--max-width);
    margin: 100px auto 0 auto;
    display: grid;
    grid-gap: 20px;
    justify-content: center;
    text-align: center;
}

.no-results-wrapper .no-results-icon i {
    font-size: 75px;
    color: var(--text-dark-secondary);
}

.no-results-wrapper .no-results-heading {
    font-size: 20px;
    font-weight: 500;
}

.no-results-wrapper .no-results-text {
    font-size: 20px;
    color: var(--text-dark-secondary);
    max-width: 300px;
}

@media only screen and (min-width: 900px) {
    .partner-grid-container .partner-wrapper:hover .partner {
        box-shadow: 0 3px 52px 0 rgba(0, 0, 0, 0.08);
        transform: scale(1.03);
    }
}
</style>